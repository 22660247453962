<template>
	<div class="resourceallocationFormHeader puiformheader">
		<v-row>
			<v-col cols="4">
				<pui-field :label="$t('header.resourceallocation.portname')" :value="getPortnameValue"></pui-field>
			</v-col>
			<v-col cols="4">
				<pui-field :label="$t('header.resourceallocation.portcallnumber')" :value="getPortcallnumberValue"></pui-field>
			</v-col>
			<v-col cols="4">
				<pui-field :label="$t('header.resourceallocation.vesselname')" :value="getVesselnameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'resourceallocationFormHeader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'resourceallocation'
		};
	},
	props: {
		portcallnumber: {
			type: String,
			required: false
		},
		vesselname: {
			type: String,
			required: false
		}
	},
	computed: {
		getPortnameValue() {
			return this.model && this.model.portname ? this.model.portname : '-';
		},
		getPortcallnumberValue() {
			if (this.portcallnumber) {
				return this.portcallnumber;
			}
			return this.model && this.model.portcallnumber ? this.model.portcallnumber : '-';
		},
		getVesselnameValue() {
			if (this.vesselname) {
				return this.vesselname;
			}
			return this.model && this.model.vesselname ? this.model.vesselname : '-';
		}
	}
};
</script>
